import React from 'react'
import Layout from '../../components/layout'

import {
	Container,
	Section,
	Row,
	Column,
} from '../../components/layouts'

import {
	ThankYouMsj,
} from '../../components/ui'

import theme from '../../theme/theme'

const {
	oneTimeDonation,
} = theme.sections


// TODO: Specific error for page
const ThankYou = () => {
	return (
		<Layout>
			<Section bg={oneTimeDonation.bg} paddingTop={'8em'} paddingBottom={'3em'}>
				<Container maxWidth={'840px'}>
					<Row>
						<Column>
							<div style={{ background: 'white', minHeight: 250,
								boxShadow: "0 6px 8px 0 rgba(53, 53, 53, 0.17)",
								borderRadius: 8,
								width: '100%', overflowY: 'scroll' }}>
								<ThankYouMsj
									title={"Thanks for the information!"}
									description1={"It will take us about 48 hours to process"}
									description2={"Please expect a call when your account has been verified."}
									image={theme.sections.donateNow.forms.thanks.image}
									note={'Welcome to Flourish!'}
									giveBtn={theme.sections.donateNow.forms.thanks.giveBtn}
									homeBtn={theme.sections.donateNow.forms.thanks.homeBtn}
									redirect={'/nonprofits'}
								/>
							</div>
						</Column>
					</Row>
				</Container>
			</Section>
		</Layout>
	)
}

export default ThankYou
